export const determineOnboardingSteps = ({
  user,
  accountId,
  accounts,
  location,
  routes,
}) => {
  const locationPathSegments = location.pathname.split('/').filter(Boolean)
  const account = accountId
    ? accounts.find((a) => a.id === accountId)
    : accounts?.length > 0
      ? accounts[0]
      : null
  const accountIsSubscribed =
    account &&
    !!(account.test || account.externalBilling || account.stripeSubscriptionId)
  const steps = [
    {
      id: 'user-profile',
      title: 'Complete your profile',
      subtitle: 'Enter your name, ID, email, and phone number.',
      route: routes.onboarding(),
      current: locationPathSegments.length === 1,
      completed: !!user?.hasCompletedOnboarding,
    },
    ...(account && !accountIsSubscribed
      ? [
          {
            id: 'account',
            title: 'Add account information',
            subtitle: 'Enter information about your account.',
            route: routes.onboardingAccount({ id: account.id }),
            current: location.pathname === `/onboarding/${account.id}`,
            completed: !!account.onboardingCompletedAt,
          },
          {
            id: 'import-cost-centers',
            title:
              account.costCenterCount === 0
                ? 'Add cost centers'
                : 'Confirm cost centers',
            subtitle:
              'Make sure each cost center has a name and ID. You can assign managers and associates to these cost centers in a later step.',
            route: routes.accountCostCenterOnboarding({ id: account.id }),
            current:
              location.pathname === `/onboarding/${account.id}/cost_centers`,
            completed: account.costCenterCount > 0,
          },
          {
            id: 'import-associates',
            title: 'Import associates',
            subtitle:
              'Upload your Associate Group Summary Report from PeopleHub',
            route: routes.accountAssociateOnboarding({ id: account.id }),
            current:
              location.pathname === `/onboarding/${account.id}/associates`,
            completed: account.associateCount > 0,
          },
          {
            id: 'select-plan',
            title: 'Select a plan',
            route: routes.accountPlanOnboarding({ id: account.id }),
            current: location.pathname === `/onboarding/${account.id}/plan`,
            completed: accountIsSubscribed,
          },
        ]
      : []),
    // ...(showOccurrenceBackFillStep
    //   ? [
    //       {
    //         id: 'occurrence-back-fill',
    //         title: 'Back fill occurrences',
    //         subtitle: 'Enter occurrences for your associates.',
    //         route: routes.onboardingOccurrences(),
    //         current: location.pathname === `/onboarding/occurrences`,
    //         completed: false,
    //       },
    //     ]
    //   : []),
  ]

  const currentStep = steps.find((step) => step.current)
  const currentStepIndex = steps.findIndex((step) => step.current)
  const previousStep = currentStepIndex > 0 ? steps[currentStepIndex - 1] : null
  const nextStep =
    currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null
  const nextIncompleteStep = steps.find((step) => !step.completed)

  return {
    account,
    steps,
    nextStep,
    nextIncompleteStep,
    currentStep,
    currentStepIndex,
    previousStep,
    showSteps: !!account && !accountIsSubscribed,
  }
}
