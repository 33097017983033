export const COVERAGE_SHIFT_SETTING_FIELDS = `
  id
  enabled
  coverageShiftGroupRules
  allowCoverageShiftGroupOverride
  shiftGrantMethod
  shiftGrantPriority
  createLevels
  notificationLevels
  notificationMethod
  sendRejectionNotification
  smsNotificationShiftOffer
  smsNotificationShiftOffer_ES
  smsNotificationShiftOfferReplyConfirm
  smsNotificationShiftOfferReplyConfirm_ES
  smsNotificationShiftConfirmation
  smsNotificationShiftConfirmation_ES
  smsNotificationShiftRejection
  smsNotificationShiftRejection_ES
  phoneNotificationShiftOffer
  phoneNotificationShiftOffer_ES
  phoneNotificationShiftConfirmation
  phoneNotificationShiftConfirmation_ES
  phoneNotificationShiftRejection
  phoneNotificationShiftOfferReplyConfirm
  phoneNotificationShiftOfferReplyConfirm_ES
  replyTimeThreshold
  replyTimeLower
  replyTimeUpper
`

export const GET_COVERAGE_SHIFT = gql`
  query GetCoverageShift($id: String!) {
    coverageShift(id: $id) {
      id
      account {
        id
        name
        coverageShiftSettings {
          ${COVERAGE_SHIFT_SETTING_FIELDS}
        }
      }
      costCenter {
        id
        name
      }
      shiftGrantMethod
      coverageShiftGroups {
        id
        status
        firstContact
        priority
        label
        rules
        replyTimeExpiredAt
        lastCheckedAt
        decisions {
          id
          status
          timestamp
          reason
          associate {
            id
            name
            externalId
            jobTitle
            qualifiedPositions
            status
            hiredOn
            positionChangedOn
            email
            phoneNumber
            onProbation
            probationEndsOn
            account {
              id
              name
            }
            costCenters {
              id
              name
              deletedAt
            }
          }
        }
      }
      date
      startTime
      endTime
      position
      positionCount
      reason
      absenceRequests {
        id
        selfReportedAbsenceRequest {
          id
          confirmationNumber
          startDate
          endDate
          absenceType
          associate {
            id
            name
            externalId
          }
        }
      }
      status
      replyTime
      createdBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_COVERAGE_SHIFTS = gql`
  query GetCoverageShifts(
    $accountIds: [String!]
    $costCenterIds: [String!]
    $status: String
    $position: String
    $reason: String
    $reasonText: String
    $page: Int
    $perPage: Int
  ) {
    coverageShifts(
      accountIds: $accountIds
      costCenterIds: $costCenterIds
      position: $position
      status: $status
      reason: $reason
      reasonText: $reasonText
      page: $page
      perPage: $perPage
    ) {
      hasAnyCoverageShifts
      coverageShifts {
        id
        account {
          id
          name
          coverageShiftSettings {
            ${COVERAGE_SHIFT_SETTING_FIELDS}
          }
        }
        costCenter {
          id
          name
        }
        date
        startTime
        endTime
        position
        positionCount
        status
        reason
        absenceRequests {
          id
          selfReportedAbsenceRequest {
            id
            confirmationNumber
            startDate
            endDate
            absenceType
            associate {
              id
              name
              externalId
            }
          }
        }
        createdBy {
          id
          name
          email
        }
        createdAt
        updatedAt
        decisions {
          id
          status
          timestamp
          associate {
            id
            name
            externalId
          }
          user {
            id
            name
          }
        }
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const CREATE_COVERAGE_SHIFT = gql`
  mutation CreateCoverageShift(
    $accountId: String!
    $input: CreateCoverageShiftInput!
  ) {
    createCoverageShift(accountId: $accountId, input: $input) {
      id
      accountId
      costCenterId
      shiftGrantMethod
      coverageShiftGroups {
        status
        priority
        label
        rules
      }
      date
      startTime
      endTime
      position
      positionCount
      status
      replyTime
      createdById
      createdAt
      updatedAt
      deletedAt
      reason
    }
  }
`

export const CREATE_COVERAGE_SHIFT_SETTINGS = gql`
  mutation CreateCoverageShiftSettings($accountId: String!) {
    createCoverageShiftSettings(accountId: $accountId) {
      id
      accountId
      enabled
      coverageShiftGroupRules
      allowCoverageShiftGroupOverride
      shiftGrantMethod
      createLevels
      notificationLevels
      notificationMethod
      sendRejectionNotification
      smsNotificationShiftOffer
      smsNotificationShiftConfirmation
      phoneNotificationShiftOffer
      phoneNotificationShiftConfirmation
      phoneNotificationShiftRejection
      smsNotificationShiftRejection
      replyTimeThreshold
      replyTimeLower
      replyTimeUpper
    }
  }
`

export const UPDATE_COVERAGE_SHIFT_SETTINGS_MUTATION = gql`
  mutation UpdateCoverageShiftSettingsMutation(
    $id: String!
    $input: UpdateCoverageShiftSettingsInput!
  ) {
    updateCoverageShiftSettings(id: $id, input: $input) {
      id
    }
  }
`

export const CLOSE_COVERAGE_SHIFT = gql`
  mutation CloseCoverageShift($id: String!) {
    closeCoverageShift(id: $id) {
      id
    }
  }
`

export const START_COVERAGE_SHIFT = gql`
  mutation StartCoverageShift($id: String!) {
    startCoverageShift(id: $id) {
      id
    }
  }
`

export const COVERAGE_SHIFT_EVENTS = gql`
  query CoverageShiftEvents(
    $shiftId: String!
    $types: [String]
    $limit: Int
    $page: Int
  ) {
    coverageShift(id: $shiftId) {
      events(types: $types, limit: $limit, page: $page) {
        events {
          id
          type
          description
          data
          updatedAt
          createdAt
          user {
            id
            name
          }
          group {
            id
            status
            firstContact
            priority
            label
            rules
            replyTimeExpiredAt
            lastCheckedAt
          }
          notification {
            id
            type
            method
            message
            sentAt
            status
            to
            response
            respondedAt
            errorCode
            errorMessage
          }
          decision {
            id
            status
            reason
            timestamp
            associate {
              id
              name
              externalId
            }
          }
          associate {
            id
            name
            externalId
            jobTitle
            qualifiedPositions
            email
            phoneNumber
          }
        }
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
