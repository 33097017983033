import { EXCUSED_ABSENCE_FIELDS } from './excusedAbsences'
import { OCCURRENCE_INSTANCE_FIELDS } from './occurrences'
import { POLICY_RULE_SET_FIELDS } from './policyRuleSets'
import { WORK_RULE_VIOLATION_FIELDS } from './workRules'

export const ASSOCIATE_FIELDS = `
  id
  externalId
  firstName
  lastName
  qualifiedPositions
  name
  email
  phoneNumber
  status
  jobTitle
  hiredOn
  positionChangedOn
  isSupervisor
  onProbation
  probationEndsOn
  initialProbationEndsOn
  probationEndsOnChangedById
  probationEndsOnChangedBy {
    id
    name
  }
  probationEndsOnChangeReason
  terminatedAt
  terminationReason
  isTerminated
  occurrencesImportedAt
  address {
    id
    googlePlaceId
    fullAddress
    address1
    city
    state
    zip
  }
  costCenters {
    id
    name
    externalId
    deletedAt
  }
  costCenterId
  associateTransferRequests {
    id
    createdAt
    decision
    decisionDate
    costCenter {
      id
      name
      externalId
    }
    associate {
      id
    }
    canApprove
    canCancel
  }
  associateTerminationRequests {
    id
    createdAt
    decision
    decisionDate
    associate {
      id
    }
    terminationDate
    reason
    canApprove
    canCancel
    decisionBy {
      id
      name
    }
  }
  terminatedBy {
    id
    name
  }
  terminationApprovedBy {
    id
    name
  }
  account {
    id
    name
    logoUrl
    collectiveBargainingAgreement
    isUnion
    introductoryAssociatesAreUnionized
    hiddenFeatures
    allowViolationExpiryOverride
    allowOccurrenceCounselingLevelChange
    allowWrvCounselingLevelChange
    wrvApprovalRoles
    stripeSubscriptionId
    stripePlanName
    externalBilling
    supportedLanguages
    wrvCreateRoles
    occurrenceCounselingRequiresApproval
    occurrenceCounselingApprovalRoles
    occurrenceDeleteLevels
    wrvDeleteLevels
    supportOccurrenceVerificationDocument
    addonsEnabled
    test
    selfReportedAbsencesPhoneNumber
    allowHistoricalWorkRuleViolations
    allowHistoricalWorkRuleViolations
  }
  policyRuleSet {
    ${POLICY_RULE_SET_FIELDS}
  }
  activePoints
  ncnsPoints
  occurrencesImportedAt
  vacationDaysRemaining
  sickDaysRemaining
  floatingHolidayDaysRemaining
  preferredLanguage
  preferredNotificationMethod
  signaturePageUrls
  associateTracks {
    track {
      id
      title
      enabled
    }
    points
  }
`

export const ASSOCIATE_QUERY = ({
  includeFirstUncounseled = false,
} = {}) => gql`
  query AssociateQuery($id: String!) {
    associate(id: $id) {
      ${ASSOCIATE_FIELDS}
      qualifiedPositions
      hasOutstandingCounseling
      user {
        id
        lastLoginAt
        status
        invitedAt
        inviteUrl
      }
      ${
        includeFirstUncounseled
          ? `
        firstUncounseledOccurrence {
          id
          date
          minutesLateOrLeftEarly
          type {
            name
          }
        }
        firstUncounseledWorkRuleViolation {
          id
          date
        }
      `
          : ''
      }
    }
  }
`

export const ASSOCIATE_SEARCH_QUERY = gql`
  query AssociateSearchQuery($searchValue: String!, $accountId: String) {
    searchAssociates(searchValue: $searchValue, accountId: $accountId) {
      id
      email
      externalId
      name
      firstName
      lastName
      jobTitle
      account {
        id
        name
      }
      costCenters {
        id
        name
        externalId
        deletedAt
      }
      policyRuleSet {
        ${POLICY_RULE_SET_FIELDS}
      }
    }
  }
`

export const ASSOCIATE_PRINT_QUERY = gql`
  query AssociatePrintQuery($id: String!) {
    associate(id: $id) {
      ${ASSOCIATE_FIELDS}
      activeOccurrences(sort: "date", sortDirection: "desc") {
        ${OCCURRENCE_INSTANCE_FIELDS}
      }
      activeWorkRuleViolations(sort: "date", sortDirection: "desc") {
        ${WORK_RULE_VIOLATION_FIELDS}
      }
      activeExcusedAbsences(sort: "startDate", sortDirection: "desc") {
        ${EXCUSED_ABSENCE_FIELDS}
      }
    }
  }
`

export const ASSOCIATE_IMPORT_QUERY = gql`
  query associatesImportQuery($input: AssociatePageInput!) {
    associatePage(input: $input) {
      managedAssociatesCount
      importedManagedAssociatesCount
      associates {
        id
        externalId
        firstName
        lastName
        name
        email
        phoneNumber
        costCenterId
        costCenters {
          id
          name
          externalId
          deletedAt
        }
        status
        jobTitle
        hiredOn
        probationEndsOn
        isSupervisor
        onProbation
        activePoints
        account {
          id
        }
        occurrencesImportedAt
        account {
          allowViolationExpiryOverride
          allowOccurrenceCounselingLevelChange
          allowWrvCounselingLevelChange
        }
        policyRuleSet {
          ${POLICY_RULE_SET_FIELDS}
        }
        occurrences(sort: "date", sortDirection: "asc") {
          id
          date
          endDate
          counselingDate
          counselingType
          counselingIssued
          occurrenceTypeId
          occurrenceTypeName
          ncnsCounselingDate
          ncnsCounselingType
          ncnsCounselingIssued
          trackOccurrenceInstances {
            id
            points
            counselingIssued
            counselingDate
            counselingType
            signedCounselingSheetUrl
            trackId
          }
        }
        activeExcusedAbsences {
          ${EXCUSED_ABSENCE_FIELDS}
        }
        associateTracks {
          track {
            id
            title
            enabled
          }
          points
        }
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const ASSOCIATES_QUERY = gql`
  query associatesQuery($input: AssociatePageInput!) {
    associatePage(input: $input) {
      total
      associates {
        id
        externalId
        firstName
        lastName
        qualifiedPositions
        name
        email
        phoneNumber
        costCenterId
        status
        jobTitle
        hiredOn
        isSupervisor
        onProbation
        probationEndsOn
        terminatedAt
        terminationReason
        terminationApprovedBy {
          id
          name
        }
        isTerminated
        occurrences {
          id
          type {
            name
            points
            key
          }
          date
          occurrenceTypeId
          points
          ncnsPoints
          occurrenceTypeName
          occurrenceTypeKey
          signedCounselingSheetUrl
          notes
          counselingIssued
          counselingDate
          counselingType
          ncnsCounselingIssued
          ncnsCounselingDate
          ncnsCounselingType
          costCenter {
            externalId
            name
          }
          status
          verificationDocumentUrl
          trackOccurrenceInstances {
            id
            points
            counselingIssued
            counselingDate
            counselingType
            signedCounselingSheetUrl
            trackId
          }
        }
        address {
          id
          googlePlaceId
          address1
          city
          state
          zip
          fullAddress
        }
        associateTerminationRequests {
          id
          decision
        }
        account {
          id
          name
          collectiveBargainingAgreement
          isUnion
          hiddenFeatures
          introductoryAssociatesAreUnionized
          occurrenceCounselingRequiresApproval
          occurrenceCounselingApprovalRoles
        }
        policyRuleSet {
          ${POLICY_RULE_SET_FIELDS}
        }
        costCenters {
          id
          name
          externalId
          deletedAt
        }
        activePoints
        ncnsPoints
        signaturePageUrls
      }
      hasNextPage
      hasPreviousPage
    }
  }
`

export const ASSOCIATE_DELETE_MUTATION = gql`
  mutation deleteAssociate($input: DeleteAssociateInput!) {
    deleteAssociate(input: $input) {
      id
    }
  }
`

export const ASSOCIATE_CREATE_MUTATION = gql`
  mutation createAssociate($input: CreateAssociateInput!) {
    createAssociate(input: $input) {
      id
      userErrors {
        field
        message
      }
    }
  }
`

export const ASSOCIATE_UPDATE_MUTATION = gql`
  mutation updateAssociate($input: UpdateAssociateInput!) {
    updateAssociate(input: $input) {
      id
      preferredLanguage
      preferredNotificationMethod
      userErrors {
        field
        message
      }
    }
  }
`

export const BULK_EDIT_ASSOCIATES = gql`
  query BulkEditAssociates($ids: [String]) {
    bulkEditAssociates(ids: $ids) {
      id
      externalId
      firstName
      lastName
      name
      email
      phoneNumber
      status
      jobTitle
      qualifiedPositions
      hiredOn
      positionChangedOn
      address {
        id
        googlePlaceId
        fullAddress
        address1
        city
        state
        zip
      }
      preferredLanguage
      preferredNotificationMethod
    }
  }
`

export const MANAGED_ASSOCIATES_QUERY = gql`
  query ManagedAssociatesQuery {
    managedAssociates {
      totalAssociatesCount
      importedAssociatesCount
    }
  }
`

export const ASSOCIATE_BULK_UPDATE_MUTATION = gql`
  mutation bulkUpdateAssociate($input: [UpdateAssociateInput!]!) {
    bulkUpdateAssociate(input: $input) {
      id
      externalId
      firstName
      lastName
      name
      email
      phoneNumber
      status
      jobTitle
      qualifiedPositions
      hiredOn
      positionChangedOn
      address {
        id
        googlePlaceId
        fullAddress
        address1
        city
        state
        zip
      }
      preferredLanguage
      preferredNotificationMethod
    }
  }
`

export const ASSOCIATES_EXPORT_MUTATION = gql`
  mutation exportAssociates($input: ExportAssociatesInput!) {
    exportAssociates(input: $input) {
      url
    }
  }
`

export const ASSOCIATES_IMPORT_MUTATION = gql`
  mutation importAssociates($input: ImportAssociatesInput!) {
    importAssociates(input: $input) {
      success
    }
  }
`

export const TRANSFER_REQUEST_UPDATE_MUTATION = gql`
  mutation updateAssociateTransferRequest(
    $input: UpdateAssociateTransferRequestInput!
  ) {
    updateAssociateTransferRequest(input: $input) {
      id
    }
  }
`

export const TRANSFER_REQUEST_CREATE_MUTATION = gql`
  mutation createAssociateTransferRequest(
    $input: CreateAssociateTransferRequestInput!
  ) {
    createAssociateTransferRequest(input: $input) {
      id
    }
  }
`

export const TERMINATION_REQUEST_UPDATE_MUTATION = gql`
  mutation updateAssociateTerminationRequest(
    $input: UpdateAssociateTerminationRequestInput!
  ) {
    updateAssociateTerminationRequest(input: $input) {
      id
    }
  }
`

export const TERMINATION_REQUEST_CREATE_MUTATION = gql`
  mutation createAssociateTerminationRequest(
    $input: CreateAssociateTerminationRequestInput!
  ) {
    createAssociateTerminationRequest(input: $input) {
      id
    }
  }
`

export const UNDO_ASSOCIATE_TERMINATION_MUTATION = gql`
  mutation undoAssociateTermination($id: String!) {
    undoAssociateTermination(id: $id) {
      id
    }
  }
`
