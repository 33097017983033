import { useEffect, useState } from 'react'

import { Frame, Layout, Loading, Page, Stack, TopBar } from '@shopify/polaris'
import { LogOutMinor } from '@shopify/polaris-icons'
import { useImmer } from 'use-immer'

import { routes, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'
import GATracker from 'src/components/shared/GATracker'
import { OnboardingProvider } from 'src/contexts/onboarding'
import { UNSUBSCRIBED_ACCOUNTS_QUERY } from 'src/graphql/accounts'
import { APP_USER_QUERY } from 'src/graphql/users'
import { determineOnboardingSteps } from 'src/helpers/onboarding'
import { initials, userDisplayString } from 'src/helpers/strings'

import { PermissionsProvider } from '../../contexts/permissions'

import logo from './logo.png'

const OnboardingLayout = ({ loading, user, children }) => {
  const { logOut } = useAuth()
  const location = useLocation()

  const [showUserMenu, setShowUserMenu] = useState(false)
  const toggleShowUserMenu = () => setShowUserMenu(!showUserMenu)

  const associate = user?.associate

  const userMenuMarkup = !loading && (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: 'Log out',
              icon: LogOutMinor,
              onAction: logOut,
            },
          ],
        },
      ]}
      name={userDisplayString(user)}
      detail={associate?.costCenter?.name}
      initials={initials(user)}
      open={showUserMenu}
      onToggle={toggleShowUserMenu}
    />
  )

  let Container

  if (!location.pathname.startsWith('/onboarding/occurrences')) {
    Container = ({ children: _children }) => (
      <Page
        narrowWidth={!location.pathname.startsWith('/onboarding/occurrences')}
        fullWidth={location.pathname.startsWith('/onboarding/occurrences')}
      >
        <Layout sectioned>
          <Stack spacing="loose" vertical>
            {_children}
          </Stack>
        </Layout>
      </Page>
    )
  } else {
    Container = ({ children: _children }) => children
  }

  return (
    <Frame
      topBar={<TopBar userMenu={userMenuMarkup} />}
      logo={{
        topBarSource: logo,
        url: '/',
        accessibilityLabel: 'TrackPath',
      }}
    >
      <GATracker />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
          <Container>{children}</Container>
        </>
      )}
    </Frame>
  )
}

const OnboardingLayoutContainer = ({ children }) => {
  const location = useLocation()
  const { id } = useParams()
  const { loading, data } = useQuery(APP_USER_QUERY)

  const user = data?.currentUser

  const {
    data: unsubscribedAccountsData,
    loading: unsubscribedAccountsLoading,
  } = useQuery(UNSUBSCRIBED_ACCOUNTS_QUERY)

  const [onboardingSteps, setOnboardingSteps] = useImmer(null)

  useEffect(() => {
    if (user && unsubscribedAccountsData?.currentUser) {
      setOnboardingSteps(
        determineOnboardingSteps({
          user,
          accountId: id,
          accounts: unsubscribedAccountsData?.currentUser?.accountOnboarding,
          location,
          routes,
        })
      )
    }
  }, [user, unsubscribedAccountsData, location])

  if (loading || unsubscribedAccountsLoading || !onboardingSteps) {
    return ''
  }

  return (
    <PermissionsProvider currentUser={user}>
      <OnboardingProvider onboardingSteps={onboardingSteps}>
        <OnboardingLayout loading={loading} user={user}>
          <div style={{ paddingBottom: '40px' }}>{children}</div>
        </OnboardingLayout>
      </OnboardingProvider>
    </PermissionsProvider>
  )
}

export default OnboardingLayoutContainer
