import { useIdleTimer } from 'react-idle-timer'

import { Router, Route, Set } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import { useAuth } from './auth'
import BulkEditOccurrencesLayout from './layouts/BulkEditOccurrencesLayout/BulkEditOccurrencesLayout'
import MainLayout from './layouts/MainLayout/MainLayout'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import PlainLayout from './layouts/PlainLayout/PlainLayout'
import PrintLayout from './layouts/PrintLayout/PrintLayout'
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout/UnauthenticatedLayout'

const MainLoader = () => <MainLayout skeleton />

const INACTIVE_TIMEOUT = 1000 * 60 * 30

const Routes = () => {
  const { logOut, isAuthenticated } = useAuth()

  // useIdleTimer({
  //   timeout: INACTIVE_TIMEOUT,
  //   crossTab: true,
  //   onPresenceChange: (isIdle) => {
  //     if (isIdle && isAuthenticated) {
  //       // TODO: Custom element
  //       toast.error('You have been logged out due to inactivity.')
  //       logOut()
  //     }
  //   },
  // })

  return (
    <Router useAuth={useAuth}>
      <Set wrap={UnauthenticatedLayout}>
        <Route path="/login" page={LoginPage} name="login" />
        <Route path="/logout" page={LogoutPage} name="logout" />
        <Route path="/signup" page={SignupPage} name="signup" />
        <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
        <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
        <Route path="/invite" page={UserInvitePage} name="userInvite" />
        <Route path="/request-access" page={RequestAccessPage} name="requestAccess" />
      </Set>
      <Set wrap={OnboardingLayout} private unauthenticated="login">
        <Route path="/onboarding" page={OnboardingPage} name="onboarding" />
        <Route path="/onboarding/occurrences" page={AddOccurrencesPage} name="onboardingOccurrences" />
        <Route path="/onboarding/{id}" page={AccountOnboardingPage} name="onboardingAccount" />
        <Route path="/onboarding/{id}/cost_centers" page={AccountCostCenterOnboardingPage} name="accountCostCenterOnboarding" />
        <Route path="/onboarding/{id}/associates" page={AccountAssociateOnboardingPage} name="accountAssociateOnboarding" />
        <Route path="/onboarding/{id}/plan" page={AccountPlanOnboardingPage} name="accountPlanOnboarding" />
      </Set>
      <Set wrap={PlainLayout} private unauthenticated="login">
        <Route path="/bulk_edit" page={AssociateBulkEditorPage} name="associateBulkEditor" />
      </Set>
      <Set wrap={BulkEditOccurrencesLayout} private unauthenticated="login">
        <Route path="/associates/occurrences" page={AddOccurrencesMainPage} name="associateOccurrences" />
      </Set>
      <Set wrap={MainLayout} whileLoadingAuth={MainLoader} private unauthenticated="login">
        <Route path="/" redirect="/associates" />
        <Route path="/settings" page={SettingsPage} name="settings" />
        <Route path="/associates" page={AssociatesPage} name="associates" />
        <Route path="/associates/counseling" page={AssociateCounselingPage} name="associateCounseling" />
        <Route path="/associates/occurrence_approvals" page={OccurrenceApprovalsPage} name="occurrenceApprovals" />
        <Route path="/associates/transfers" page={AssociateTransfersPage} name="associateTransfers" />
        <Route path="/associates/terminations" page={TerminatedAssociatesPage} name="associateTerminations" />
        <Route path="/associates/absences" page={SelfReportedAbsencesPage} name="associateAbsences" />
        <Route path="/associates/absences/{id}" page={SelfReportedAbsencePage} name="associateAbsence" />
        <Route path="/cover_shifts" page={CoverageShiftsPage} name="associateCoverageShifts" />
        <Route path="/cover_shifts/add" page={AddCoverageShiftsPage} name="associateCoverageShiftsAdd" />
        <Route path="/cover_shifts/{id}" page={EditCoverageShiftsPage} name="associateCoverageShiftsEdit" />
        <Route path="/cover_shifts/{id}/events" page={CoverageShiftsMessageHistoryPage} name="associateCoverageShiftMessageHistory" />
        <Route path="/associates/grievances" page={AssociateGrievancesPage} name="associateGrievances" />
        <Route path="/associates/{id}" page={AssociatePage} name="associate" />
        <Route path="/associates/{id}/occurrences/new" page={AddOccurrencePage} name="addOccurrence" />
        <Route path="/associates/{id}/occurrences/{occId}" page={OccurrencePage} name="associateOccurrence" />
        <Route path="/a/{id}" page={AssociatePage} name="associateDetail" />
        <Route path="/a/{id}/occurrences/new" page={AddOccurrencePage} name="addOccurrenceDetail" />
        <Route path="/a/{id}/occurrences/{occId}" page={OccurrencePage} name="associateOccurrenceDetail" />
        <Route path="/accounts" page={AccountsPage} name="accounts" />
        <Route path="/accounts/new" page={NewAccountPage} name="newAccount" />
        <Route path="/accounts/{id}" page={AccountPage} name="account" />
        <Route path="/accounts/{id}/edit" page={AccountEditPage} name="accountEdit" />
        <Route path="/accounts/{id}/owners" page={AccountOwnersPage} name="accountOwners" />
        <Route path="/accounts/{id}/policies/new" page={NewAccountPoliciesPage} name="newAccountPolicies" />
        <Route path="/accounts/{id}/policies/{policyId}" page={EditAccountPoliciesPage} name="editAccountPolicies" />
        <Route path="/accounts/{id}/tracks/new" page={NewAccountTrackPage} name="newAccountTrack" />
        <Route path="/accounts/{id}/tracks/{trackId}" page={EditAccountTrackPage} name="editAccountTrack" />
        <Route path="/accounts/{id}/cover_shifts" page={AccountCoverageShiftsPage} name="accountCoverageShiftsSettings" />
        <Route path="/accounts/{id}/self_reported_absences" page={AccountSraPage} name="accountSraSettings" />
        <Route path="/accounts/{id}/plan" page={SubscriptionDetailsPage} name="subscriptionDetails" />
        <Route path="/accounts/{id}/select_plan" page={SelectPlanPage} name="selectPlan" />
        <Route path="/cost_centers/{id}" page={CostCenterPage} name="costCenter" />
        <Route path="/email" page={EmailPage} name="email" />
        <Route path="/email/{handle}" page={EmailPage} name="emails" />
        <Route path="/access-requests" page={AccessRequestsPage} name="accessRequests" />
        <Route path="/users" page={UsersPage} name="users" />
        <Route path="/users/new" page={NewUserPage} name="newUser" />
        <Route path="/users/{id}" page={UserPage} name="user" />
        <Route path="/reports" page={ReportsPage} name="reports" />
        <Route path="/reports/{id}" page={ReportPage} name="report" />
        <Route path="/print" page={PrintPage} name="print" />
        <Route path="/print/{id}" page={PrintPage} name="printAssociateTest" />
        <Route path="/icons" page={IconsPage} name="icons" />
        <Route path="/support" page={SupportPage} name="support" />
        <Route path="/support/{handle}" page={SupportPage} name="supportArticle" />
        <Route path="/document" page={DocumentPage} name="documents" />
        <Route path="/usage" page={UsagePage} name="usage" />
        <Route notfound page={NotFoundPage} />
      </Set>
      <Set wrap={PrintLayout} private unauthenticated="login">
        <Route path="/accounts/{id}/sra_pdf" page={SraPdfPage} name="sraPdf" />
        <Route path="/accounts/{id}/sra_business_cards" page={SraBusinessCardsPage} name="sraBusinessCards" />
      </Set>
    </Router>
  )
}

export default Routes
