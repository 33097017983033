import React from 'react'

const OnboardingContext = React.createContext()

const OnboardingProvider = ({ onboardingSteps, children }) => {
  const onboardingContext = {
    onboardingSteps,
  }

  return (
    <OnboardingContext.Provider value={onboardingContext}>
      {children}
    </OnboardingContext.Provider>
  )
}

const useOnboarding = () => React.useContext(OnboardingContext)

export { OnboardingProvider, useOnboarding }
