import dayjs from 'dayjs'

import { COUNSELING_TYPES } from '../constants/accounts'

import { ACCOUNTS, isMinnesota, FEATURES, hasFeature } from './features'

export const isUnionized = (associate) => {
  const { account } = associate
  if (['full_time_union', 'part_time_union'].includes(associate.status)) {
    return true
  }
  if (
    account.introductoryAssociatesAreUnionized === true &&
    (associate.onProbation || associate.status === 'probation')
  ) {
    return true
  }
  return false
}

export const statusString = (status) => {
  switch (status) {
    case 'full_time_union':
      return 'Full-time unionized'
    case 'full_time_non_union':
      return 'Full-time non-unionized'
    case 'part_time_union':
      return 'Part-time unionized'
    case 'part_time_non_union':
      return 'Part-time non-unionized'
    case 'probation':
      return 'Introductory period'
    case 'student_worker':
      return 'Student worker'
    default:
      return 'Unknown'
  }
}

export const COUNSELING_LEVELS = [
  'verbalWrittenWarning',
  'writtenWarning',
  'finalWarning',
  'termination',
]

export const COUNSELING_LEVELS_PROBATION = [
  'probationFinalWarning',
  'probationTermination',
]

export const NCNS_COUNSELING_LEVELS = [
  'ncnsWrittenWarning',
  'ncnsFinalWarning',
  'ncnsTermination',
]

export const NCNS_COUNSELING_LEVELS_PROBATION = [
  'ncnsProbationFinalWarning',
  'ncnsProbationTermination',
]

export const getAvailableCounselingLevels = ({
  associate,
  account,
  occurrenceDate = new Date(),
}) => {
  const { probationEndsOn } = associate

  const onProbation =
    probationEndsOn && dayjs(occurrenceDate).isBefore(dayjs(probationEndsOn))
  const counselingLevels = onProbation
    ? COUNSELING_LEVELS_PROBATION
    : COUNSELING_LEVELS

  return counselingLevels.map((level) => ({
    label: counselingLabel({ type: level, account }),
    value: level,
  }))
}

export const getAvailableNcnsCounselingLevels = ({
  associate,
  account,
  occurrenceDate = new Date(),
}) => {
  const { probationEndsOn } = associate

  const onProbation =
    probationEndsOn && dayjs(occurrenceDate).isBefore(dayjs(probationEndsOn))
  const counselingLevels = onProbation
    ? NCNS_COUNSELING_LEVELS_PROBATION
    : NCNS_COUNSELING_LEVELS

  return counselingLevels.map((level) => ({
    label: counselingLabel({ type: level, account }),
    value: level,
  }))
}

export const getRequiredCounselingType = ({
  associate,
  activeOccurrences: _activeOccurrences,
  selectedOccurrencePoints = 0,
  occurrenceDate = new Date(),
}) => {
  const {
    activePoints,
    probationEndsOn,
    policyRuleSet: { councellingThreshold },
  } = associate

  const activeOccurrences = _activeOccurrences.filter(
    (occurrence) =>
      !(
        occurrence.occurrenceTypeKey === 'failure_to_follow_meal_protocols' &&
        occurrence.points == 0
      )
  )

  // is the lowest counseling level from:
  // 1. the level hit by the active points threshold
  // 2. the next level after previous issued counseling
  // 3. the next level based on number of active counselings (next index in the list of counseling levels)

  const onProbation =
    probationEndsOn && dayjs(occurrenceDate).isBefore(dayjs(probationEndsOn))
  let counselingLevels = onProbation
    ? COUNSELING_LEVELS_PROBATION
    : COUNSELING_LEVELS

  counselingLevels = counselingLevels.filter(
    (level) => councellingThreshold[level] > 0
  )

  // get level from active points threshold
  let nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
    (counselingLevel) => {
      const threshold = councellingThreshold[counselingLevel]
      // console.log('threshold: ', threshold)
      return activePoints < threshold
    }
  )
  if (nextCounselingLevelIndexFromPoints === -1) {
    nextCounselingLevelIndexFromPoints = counselingLevels.length - 1
  }
  if (
    selectedOccurrencePoints + activePoints <
    councellingThreshold[counselingLevels[nextCounselingLevelIndexFromPoints]]
  ) {
    // if the points from the occurrence are not enough to trigger the next level, return undefined, unless the last counseling is expired, then we should set the counseling level to that
    if (nextCounselingLevelIndexFromPoints === 0) {
      return undefined
    } else {
      const lastCounseling = activeOccurrences
        .filter((occurrence) => {
          return !!occurrence.counselingType
        })
        .sort((a, b) => {
          return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
        })[0]
      if (lastCounseling && lastCounseling.counselingExpiredAt) {
        nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
          (counselingLevel) => {
            return counselingLevel === lastCounseling.counselingType
          }
        )
      } else {
        return undefined
      }
    }
  }

  // get level from previous issued counseling
  let previousCounselingLevel, nextCounselingLevelIndexFromPrevious
  const latestCounselingOccurrence = activeOccurrences
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })
    .find(
      (occurrence) =>
        !!occurrence.counselingType && !occurrence.counselingExpiredAt
    )
  if (latestCounselingOccurrence) {
    previousCounselingLevel = latestCounselingOccurrence.counselingType
    nextCounselingLevelIndexFromPrevious =
      counselingLevels.findIndex((counselingLevel) => {
        return counselingLevel === previousCounselingLevel
      }) + 1
  } else {
    nextCounselingLevelIndexFromPrevious = 0
  }

  // get level from number of active counselings
  const activeCounselingsCount = activeOccurrences.filter(
    (occurrence) =>
      !!occurrence.counselingType && !occurrence.counselingExpiredAt
  ).length
  const nextCounselingLevelIndexFromActiveCounselingsCount =
    activeCounselingsCount < counselingLevels.length
      ? activeCounselingsCount
      : counselingLevels.length - 1

  // get the next level
  const nextCounselingLevelIndex = Math.min(
    nextCounselingLevelIndexFromPoints,
    nextCounselingLevelIndexFromPrevious,
    nextCounselingLevelIndexFromActiveCounselingsCount
  )

  const nextCounselingLevel =
    nextCounselingLevelIndex <= counselingLevels.length - 1
      ? counselingLevels[nextCounselingLevelIndex]
      : counselingLevels[counselingLevels.length - 1]

  return nextCounselingLevel
}

export const getRequiredNcnsCounselingType = ({
  associate,
  activeOccurrences,
  selectedOccurrencePoints = 0,
  occurrenceDate = new Date(),
  occurrenceEndDate,
}) => {
  const {
    ncnsPoints,
    probationEndsOn,
    policyRuleSet: { councellingThreshold, maxConsecutiveNcnsDays },
  } = associate

  const onProbation =
    probationEndsOn && dayjs(occurrenceDate).isBefore(dayjs(probationEndsOn))
  let counselingLevels = onProbation
    ? NCNS_COUNSELING_LEVELS_PROBATION
    : NCNS_COUNSELING_LEVELS

  counselingLevels = counselingLevels.filter(
    (level) => councellingThreshold[level] > 0
  )

  // get level from active points threshold
  let nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
    (counselingLevel) => {
      const threshold = councellingThreshold[counselingLevel]
      return ncnsPoints < threshold
    }
  )
  if (nextCounselingLevelIndexFromPoints === -1) {
    nextCounselingLevelIndexFromPoints = counselingLevels.length - 1
  }
  if (
    selectedOccurrencePoints + ncnsPoints <
    councellingThreshold[counselingLevels[nextCounselingLevelIndexFromPoints]]
  ) {
    // if the points from the occurrence are not enough to trigger the next level, return undefined, unless the last counseling is expired, then we should set the counseling level to that
    if (nextCounselingLevelIndexFromPoints === 0) {
      return undefined
    } else {
      const lastCounseling = activeOccurrences
        .filter((occurrence) => {
          return !!occurrence.ncnsCounselingType
        })
        .sort((a, b) => {
          return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
        })[0]
      if (lastCounseling && lastCounseling.ncnsCounselingExpiredAt) {
        nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
          (counselingLevel) => {
            return counselingLevel === lastCounseling.counselingType
          }
        )
      } else {
        return undefined
      }
    }
  }

  // get level from previous issued counseling
  let previousCounselingLevel, nextCounselingLevelIndexFromPrevious
  const latestCounselingOccurrence = activeOccurrences
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })
    .find(
      (occurrence) =>
        !!occurrence.ncnsCounselingType && !occurrence.ncnsCounselingExpiredAt
    )
  if (latestCounselingOccurrence) {
    previousCounselingLevel = latestCounselingOccurrence.ncnsCounselingType
    nextCounselingLevelIndexFromPrevious =
      counselingLevels.findIndex((counselingLevel) => {
        return counselingLevel === previousCounselingLevel
      }) + 1
  } else {
    nextCounselingLevelIndexFromPrevious = 0
  }

  // get the next level
  if (
    !!occurrenceEndDate &&
    dayjs(occurrenceEndDate)
      .startOf('day')
      .diff(dayjs(occurrenceDate).startOf('day'), 'day') +
      1 >=
      maxConsecutiveNcnsDays
  ) {
    return onProbation ? 'ncnsProbationTermination' : 'ncnsTermination'
  }

  const nextCounselingLevelIndex = Math.min(
    nextCounselingLevelIndexFromPoints,
    nextCounselingLevelIndexFromPrevious
  )

  const nextCounselingLevel =
    nextCounselingLevelIndex <= counselingLevels.length - 1
      ? counselingLevels[nextCounselingLevelIndex]
      : counselingLevels[counselingLevels.length - 1]

  return nextCounselingLevel
}

export const getCounselingLevelsForTrack = ({
  track,
  associate,
  occurrenceDate,
}) => {
  if (!track) {
    return { counselingLevels: [], councellingThreshold: {} }
  }
  const onProbation =
    associate.probationEndsOn &&
    dayjs(occurrenceDate).isBefore(dayjs(associate.probationEndsOn))

  const councellingThreshold = track.counselingLevels.reduce((acc, level) => {
    if (
      level.appliesTo === 'all' ||
      (level.appliesTo === 'introductory_period' && onProbation) ||
      (level.appliesTo === 'full_time' &&
        ['full_time_union', 'full_time_non_union'].includes(
          associate.status
        )) ||
      (level.appliesTo === 'part_time' &&
        ['part_time_union', 'part_time_non_union'].includes(
          associate.status
        )) ||
      (level.appliesTo === 'full_and_part_time' && !onProbation)
    ) {
      acc[level.name] = level.points
    }
    return acc
  }, {})

  const counselingLevels = Object.keys(councellingThreshold).sort(
    (a, b) => councellingThreshold[a] - councellingThreshold[b]
  )

  return {
    counselingLevels,
    councellingThreshold,
  }
}

export const getRequiredTrackCounselingType = ({
  associate,
  track,
  activeOccurrences: _activeOccurrences,
  selectedOccurrencePoints = 0,
  occurrenceDate = new Date(),
}) => {
  const associateTrack = associate.associateTracks.find(
    (associateTrack) => associateTrack.track.id === track.id
  )

  const activePoints = associateTrack?.points || 0

  const activeOccurrences = _activeOccurrences
    .filter(
      (occurrence) =>
        !(
          occurrence.occurrenceTypeKey === 'failure_to_follow_meal_protocols' &&
          occurrence.points == 0
        )
    )
    .filter((occurrence) => {
      const trackOccurrenceInstance = occurrence.trackOccurrenceInstances.find(
        (trackOccurrence) => trackOccurrence.trackId === track.id
      )
      return !!trackOccurrenceInstance
    })
    .map((occurrence) => {
      const trackOccurrenceInstance = occurrence.trackOccurrenceInstances.find(
        (trackOccurrence) => trackOccurrence.trackId === track.id
      )
      return {
        ...occurrence,
        ...trackOccurrenceInstance,
      }
    })

  // is the lowest counseling level from:
  // 1. the level hit by the active points threshold
  // 2. the next level after previous issued counseling
  // 3. the next level based on number of active counselings (next index in the list of counseling levels)

  const { counselingLevels, councellingThreshold } =
    getCounselingLevelsForTrack({
      track,
      associate,
      occurrenceDate,
    })

  // get level from active points threshold
  let nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
    (counselingLevel) => {
      const threshold = councellingThreshold[counselingLevel]
      console.log(
        `[getRequiredTrackCounselingType][${track.title}] threshold: `,
        threshold
      )
      return activePoints < threshold
    }
  )
  if (nextCounselingLevelIndexFromPoints === -1) {
    nextCounselingLevelIndexFromPoints = counselingLevels.length - 1
  }

  if (
    selectedOccurrencePoints + activePoints <
    councellingThreshold[counselingLevels[nextCounselingLevelIndexFromPoints]]
  ) {
    // if the points from the occurrence are not enough to trigger the next level, return undefined, unless the last counseling is expired, then we should set the counseling level to that
    if (nextCounselingLevelIndexFromPoints === 0) {
      return undefined
    } else {
      const lastCounseling = activeOccurrences
        .filter((occurrence) => {
          return !!occurrence.counselingType
        })
        .sort((a, b) => {
          return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
        })[0]
      if (lastCounseling && lastCounseling.counselingExpiredAt) {
        nextCounselingLevelIndexFromPoints = counselingLevels.findIndex(
          (counselingLevel) => {
            return counselingLevel === lastCounseling.counselingType
          }
        )
      } else {
        return undefined
      }
    }
  }

  // get level from previous issued counseling
  let previousCounselingLevel, nextCounselingLevelIndexFromPrevious
  const latestCounselingOccurrence = activeOccurrences
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })
    .find(
      (occurrence) =>
        !!occurrence.counselingType && !occurrence.counselingExpiredAt
    )
  if (latestCounselingOccurrence) {
    previousCounselingLevel = latestCounselingOccurrence.counselingType
    nextCounselingLevelIndexFromPrevious =
      counselingLevels.findIndex((counselingLevel) => {
        return counselingLevel === previousCounselingLevel
      }) + 1
  } else {
    nextCounselingLevelIndexFromPrevious = 0
  }

  // get level from number of active counselings
  const activeCounselingsCount = activeOccurrences.filter(
    (occurrence) =>
      !!occurrence.counselingType && !occurrence.counselingExpiredAt
  ).length
  const nextCounselingLevelIndexFromActiveCounselingsCount =
    activeCounselingsCount < counselingLevels.length
      ? activeCounselingsCount
      : counselingLevels.length - 1

  // get the next level
  const nextCounselingLevelIndex = Math.min(
    nextCounselingLevelIndexFromPoints,
    nextCounselingLevelIndexFromPrevious,
    nextCounselingLevelIndexFromActiveCounselingsCount
  )

  const nextCounselingLevel =
    nextCounselingLevelIndex <= counselingLevels.length - 1
      ? counselingLevels[nextCounselingLevelIndex]
      : counselingLevels[counselingLevels.length - 1]

  return nextCounselingLevel
}

export const getCurrentTrackData = ({
  occurrence,
  account,
  associate,
  policyRuleSet,
  activeOccurrences,
  isMealBreakWarning,
}) => {
  const defaultViolationCounselingType = policyRuleSet.hasAttendanceTrack
    ? getRequiredCounselingType({
        associate,
        activeOccurrences,
        selectedOccurrencePoints: occurrence.points,
        occurrenceDate: occurrence.date,
        isMealBreakWarning,
      })
    : undefined

  const includeNcns =
    hasFeature({
      feature: FEATURES.NO_CALL_NO_SHOWS,
      account,
    }) && occurrence.type.key === 'no_call_no_show'

  let defaultNcnsCounselingType
  if (includeNcns) {
    defaultNcnsCounselingType = policyRuleSet.hasNcnsTrack
      ? getRequiredNcnsCounselingType({
          associate,
          activeOccurrences,
          selectedOccurrencePoints: occurrence.points,
          occurrenceDate: occurrence.date,
          occurrenceEndDate: occurrence.endDate,
        })
      : undefined
  }

  const tracks = policyRuleSet?.tracks || []
  let trackData = []

  for (const track of tracks) {
    const counselingTrackOccurrenceInstance = (
      occurrence.trackOccurrenceInstances || []
    ).find((trackOcc) => !!trackOcc.counselingType)

    trackData.push({
      trackId: track.id,
      title: track.title,
      counselingLevels: track.counselingLevels,
      points: occurrence.points,
      counselingType: counselingTrackOccurrenceInstance?.counselingType,
    })
  }

  // TODO: probably need to do the same for the NCNS track, but it's not an issue now because the first counseling level happens at one occurrence point
  if (policyRuleSet.hasAttendanceTrack) {
    const attendanceTrack = {
      trackId: 'default-attendance',
      title: 'Attendance',
      points: occurrence.points,
      counselingType:
        occurrence.counselingType === null
          ? null
          : isMealBreakWarning
            ? 'verbalWrittenWarning'
            : defaultViolationCounselingType,
      counselingThresholds: {
        verbalWrittenWarning:
          policyRuleSet.councellingThreshold.verbalWrittenWarning,
        writtenWarning: policyRuleSet.councellingThreshold.writtenWarning,
        finalWarning: policyRuleSet.councellingThreshold.finalWarning,
        termination: policyRuleSet.councellingThreshold.termination,
      },
      probationCounselingThresholds: {
        finalWarning: policyRuleSet.councellingThreshold.probationFinalWarning,
        termination: policyRuleSet.councellingThreshold.probationTermination,
      },
    }
    const enabledOccurrenceTypes = policyRuleSet.occurrenceTypes
      .filter((t) => t.enabled)
      .map((t) => t.key)
    const activePoints = activeOccurrences.reduce((acc, occurrence) => {
      if (enabledOccurrenceTypes.includes(occurrence.type.key)) {
        return acc + occurrence.points
      }
      return acc
    }, 0)

    const onProbationAtTimeOfOccurrence = dayjs(occurrence.date).isBefore(
      dayjs(associate.probationEndsOn)
    )
    const thresholds = onProbationAtTimeOfOccurrence
      ? attendanceTrack.probationCounselingThresholds
      : attendanceTrack.counselingThresholds
    const pointsRequiredForLowestLevel = Math.min(
      ...Object.values(thresholds).filter((value) => typeof value === 'number')
    )
    if (
      activePoints + occurrence.points >= pointsRequiredForLowestLevel ||
      isMealBreakWarning
    ) {
      trackData = [attendanceTrack, ...trackData]
    }
  }

  return [
    ...(includeNcns && policyRuleSet.hasNcnsTrack
      ? [
          {
            trackId: 'default-ncns',
            title: 'No Call, No Show',
            points: occurrence.points,
            counselingType: defaultNcnsCounselingType,
          },
        ]
      : []),
    ...trackData,
  ]
}

export const getNextTrackData = ({
  account,
  associate,
  policyRuleSet,
  activeOccurrences: _activeOccurrences,
  selectedOccurrenceType,
  occurrenceDate = new Date(),
  occurrenceEndDate,
}) => {
  const activeOccurrences = _activeOccurrences.filter((o) =>
    dayjs(o.date).isBefore(occurrenceDate)
  )

  let selectedOccurrencePoints = selectedOccurrenceType?.points || 0

  const defaultViolationCounselingType = policyRuleSet.hasAttendanceTrack
    ? getRequiredCounselingType({
        associate,
        activeOccurrences,
        selectedOccurrencePoints: selectedOccurrenceType?.points || 0,
        occurrenceDate,
      })
    : undefined

  const includeNsns =
    hasFeature({
      feature: FEATURES.NO_CALL_NO_SHOWS,
      account,
    }) && selectedOccurrenceType?.key === 'no_call_no_show'

  let defaultNcnsCounselingType
  if (includeNsns) {
    if (policyRuleSet.ncnsPointValue != null) {
      selectedOccurrencePoints = policyRuleSet.ncnsPointValue
    }

    defaultNcnsCounselingType = policyRuleSet.hasNcnsTrack
      ? getRequiredNcnsCounselingType({
          associate,
          activeOccurrences,
          selectedOccurrencePoints,
          occurrenceDate,
          occurrenceEndDate,
        })
      : undefined
  }

  const tracks = policyRuleSet?.tracks || []
  // console.log(`[getNextTrackData] tracks: `, tracks)
  let trackData = []
  for (const track of tracks) {
    console.log(`[getNextTrackData] track: `, track)
    // check if occurrence type is applicable to track
    // if it is then add the track to the trackData
    const trackOccurrenceType = track.occurrences.find(
      (o) =>
        o.occurrenceTypeId && o.occurrenceTypeId === selectedOccurrenceType?.id
    )
    if (trackOccurrenceType) {
      const trackCounselingType = getRequiredTrackCounselingType({
        associate,
        track,
        activeOccurrences,
        selectedOccurrencePoints: selectedOccurrenceType?.points || 0,
        occurrenceDate,
      })

      trackData.push({
        trackId: track.id,
        title: track.title,
        counselingLevels: track.counselingLevels,
        points: selectedOccurrencePoints,
        counselingType: trackCounselingType,
      })
    }
  }

  return [
    ...(policyRuleSet.hasAttendanceTrack
      ? [
          {
            trackId: 'default-attendance',
            title: 'Attendance',
            points: selectedOccurrencePoints,
            counselingType: defaultViolationCounselingType,
          },
        ]
      : []),
    ...(includeNsns && policyRuleSet.hasNcnsTrack
      ? [
          {
            trackId: 'default-ncns',
            title: 'No Call, No Show',
            points: selectedOccurrencePoints,
            counselingType: defaultNcnsCounselingType,
          },
        ]
      : []),
    ...trackData,
  ]
}

export const counselingLabels = (account) => {
  const DEFAULT_LABELS = {
    firstWarning: 'First Warning',
    secondWarning: 'Second Warning',
    verbalWrittenWarning: 'Documented Verbal Warning',
    documentedVerbalWarning: 'Documented Verbal Warning',
    writtenWarning: 'Written Warning',
    finalWarning: 'Final Warning',
    termination: 'Termination',
    probationFinalWarning: 'Final Warning',
    probationTermination: 'Termination',
    ncnsWrittenWarning: 'Written Warning',
    ncnsFinalWarning: 'Final Warning',
    ncnsTermination: 'Termination',
    ncnsProbationFinalWarning: 'Final Warning',
    ncnsProbationTermination: 'Termination',
    suspension: 'Suspension',
    verbalWarning: 'Verbal Warning',
    noteToFile: 'Note to File',
  }
  if ([ACCOUNTS.NORTHWESTERN].includes(account?.id)) {
    return {
      ...DEFAULT_LABELS,
      verbalWrittenWarning: 'First Warning',
      documentedVerbalWarning: 'First Warning',
      writtenWarning: 'Second Warning',
    }
  }
  if ([ACCOUNTS.GWU, ACCOUNTS.MIAMI].includes(account?.id)) {
    return {
      ...DEFAULT_LABELS,
      verbalWrittenWarning: 'First Warning',
      documentedVerbalWarning: 'First Warning',
      writtenWarning: 'Second Warning',
      termination: 'Suspension Pending Investigation & Decision to Terminate',
      ncnsTermination:
        'Suspension Pending Investigation & Decision to Terminate',
    }
  }
  return DEFAULT_LABELS
}

export const counselingLabel = ({ type, account, track }) => {
  if (type === 'Meal / Rest Warning') {
    return 'Meal / Rest Warning'
  }
  if (track && track.counselingLevels) {
    const labels = track.counselingLevels.reduce((acc, level) => {
      acc[level.name] = level.name
      return acc
    }, {})
    if (!type || !labels[type]) {
      return 'No counseling required'
    }
    return labels[type]
  } else {
    const labels = counselingLabels(account)
    if (!type || !labels[type]) {
      return 'No counseling required'
    }
    if (
      [COUNSELING_TYPES.FINAL_WARNING].includes(type) &&
      isMinnesota(account)
    ) {
      return labels.suspension
    }
    return labels[type]
  }
}

const green = '#80D92F'
const yellow = '#DFDC0B'
const orange = '#DF890B'
const red = '#DF0B4B'
const blue = '#3891A4'

export const colorForPoints = (account, associate, type = 'attendance') => {
  const { onProbation } = associate
  const { councellingThreshold } = account
  const points =
    type === 'attendance' ? associate.activePoints : associate.ncnsPoints

  if (type === 'attendance') {
    if (onProbation) {
      if (points >= councellingThreshold.probationTermination) {
        return red
      } else if (points >= councellingThreshold.probationFinalWarning) {
        return orange
      } else {
        return green
      }
    } else {
      if (points >= councellingThreshold.termination) {
        return red
      } else if (points >= councellingThreshold.finalWarning) {
        return orange
      } else if (points >= councellingThreshold.writtenWarning) {
        return yellow
      } else if (points >= councellingThreshold.verbalWrittenWarning) {
        return blue
      } else {
        return green
      }
    }
  } else {
    if (onProbation) {
      if (points >= councellingThreshold.ncnsProbationTermination) {
        return red
      } else if (points >= councellingThreshold.ncnsProbationFinalWarning) {
        return orange
      } else {
        return green
      }
    } else {
      if (points >= councellingThreshold.ncnsTermination) {
        return red
      } else if (points >= councellingThreshold.ncnsFinalWarning) {
        return orange
      } else if (points >= councellingThreshold.ncnsWrittenWarning) {
        return yellow
      } else {
        return green
      }
    }
  }
}

export const colorForLevel = (level) => {
  if (
    [
      'termination',
      'probationTermination',
      'ncnsTermination',
      'ncnsProbationTermination',
    ].includes(level)
  )
    return red
  if (
    [
      'finalWarning',
      'probationFinalWarning',
      'ncnsFinalWarning',
      'ncnsProbationFinalWarning',
    ].includes(level)
  )
    return orange
  if (['writtenWarning', 'ncnsWrittenWarning'].includes(level)) return yellow
  if (
    [
      'verbalWrittenWarning',
      'documentedVerbalWarning',
      'Meal / Rest Warning',
    ].includes(level)
  )
    return blue
  return green
}

export const colorForTrackLevel = (level, trackCounselingLevels) => {
  const orderedLevels = [...trackCounselingLevels].sort(
    (a, b) => b.points - a.points
  )
  const colors = [red, orange, yellow, blue]
  const colorIndex = orderedLevels.findIndex((l) => l.name === level)
  return colors[colorIndex] || green
}

export const nextValidDate = ({
  events = [],
  onlyLastWeek = true,
  type = 'occurrence',
  account,
  associate,
}) => {
  let firstValidDate
  if (onlyLastWeek) {
    firstValidDate = dayjs().subtract(7, 'days')
  } else if (account) {
    firstValidDate = dayjs().subtract(
      type === 'occurrence'
        ? associate.policyRuleSet.occurrenceActiveMonths
        : associate.policyRuleSet.wrvActiveMonths,
      'month'
    )
  } else {
    firstValidDate = dayjs().subtract(12, 'month')
  }

  if (events.length > 0) {
    const mostRecentOccurrenceDate = dayjs(events[0].date)
    if (mostRecentOccurrenceDate.isAfter(firstValidDate)) {
      firstValidDate = mostRecentOccurrenceDate
    }
  }
  return firstValidDate.toDate()
}

export const currentTrackCounselingType = (occurrences) => {
  const sortedUncounselledOccurrences = occurrences
    .filter((occ) => {
      return (
        !occ.counselingIssued &&
        !occ.counselingDate &&
        !!occ.counselingType &&
        !occ.counselingExpiredAt
      )
    })
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })

  // Most recent counseled occurrence type
  if (sortedUncounselledOccurrences.length > 0) {
    return sortedUncounselledOccurrences.map((occ) => occ.counselingType)[0]
  } else {
    return occurrences
      .filter((occ) => {
        return (
          occ.counselingIssued && occ.counselingDate && !!occ.counselingType
        )
      })
      .sort((a, b) => {
        return dayjs(a.counselingDate).isBefore(dayjs(b.counselingDate))
          ? 1
          : -1
      })
      .map((occ) => occ.counselingType)[0]
  }
}

export const currentAttendanceCounselingType = (occurrences) => {
  const sortedUncounselledOccurrences = occurrences
    .filter((occ) => occ.active)
    .filter((occ) => {
      return (
        !occ.counselingIssued &&
        !occ.counselingDate &&
        !!occ.counselingType &&
        !occ.counselingExpiredAt
      )
    })
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })

  // Most recent counseled occurrence type
  if (sortedUncounselledOccurrences.length > 0) {
    return sortedUncounselledOccurrences.map((occ) => occ.counselingType)[0]
  } else {
    return occurrences
      .filter((occ) => occ.active)
      .filter((occ) => {
        return (
          occ.counselingIssued && occ.counselingDate && !!occ.counselingType
        )
      })
      .sort((a, b) => {
        return dayjs(a.counselingDate).isBefore(dayjs(b.counselingDate))
          ? 1
          : -1
      })
      .map((occ) => occ.counselingType)[0]
  }
}

export const currentNcnsCounselingType = (occurrences) => {
  const sortedUncounselledOccurrences = occurrences
    .filter((occ) => occ.active)
    .filter((occ) => {
      return (
        !occ.ncnsCounselingIssued &&
        !occ.ncnsCounselingDate &&
        !!occ.ncnsCounselingType &&
        !occ.ncnsCounselingExpiredAt
      )
    })
    .sort((a, b) => {
      return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1
    })

  // Most recent counseled occurrence type
  if (sortedUncounselledOccurrences.length > 0) {
    return sortedUncounselledOccurrences.map((occ) => occ.ncnsCounselingType)[0]
  } else {
    return occurrences
      .filter((occ) => occ.active)
      .filter((occ) => {
        return (
          occ.ncnsCounselingIssued &&
          occ.ncnsCounselingDate &&
          !!occ.ncnsCounselingType
        )
      })
      .sort((a, b) => {
        return dayjs(a.ncnsCounselingDate).isBefore(dayjs(b.ncnsCounselingDate))
          ? 1
          : -1
      })
      .map((occ) => occ.ncnsCounselingType)[0]
  }
}

export const getSickDaysBeforeUnexcusedAbsence = ({
  associate,
  policyRuleSet,
  fromDate,
}) => {
  if (!policyRuleSet || !policyRuleSet.sickDaysBeforeUnexcusedAbsence) {
    return 0
  }

  fromDate = fromDate || new Date()

  const onProbation =
    associate.probationEndsOn &&
    dayjs(fromDate).isBefore(dayjs(associate.probationEndsOn))

  if (onProbation) {
    return 0
  }

  let lastResetDate = new Date(
    new Date().getFullYear(),
    (policyRuleSet?.sickDaysRefreshMonth || 1) - 1,
    policyRuleSet?.sickDaysRefreshDay || 1
  )
  while (dayjs(fromDate).isBefore(dayjs(lastResetDate))) {
    lastResetDate = dayjs(lastResetDate).subtract(1, 'year').toDate()
  }

  if (associate.hiredOn && dayjs(associate.hiredOn).isAfter(lastResetDate)) {
    // they were hired after the last reset date, use initialSickDaysDates to determine the number of sick days they have
    const initialSickDaysDates = policyRuleSet.initialSickDaysDates || []
    const firstDateAfterHire = initialSickDaysDates.find((d) => {
      const date = new Date(dayjs(associate.hiredOn).year(), d.month - 1, d.day)
      return dayjs(date).isAfter(associate.hiredOn)
    })
    return firstDateAfterHire?.amount || 0
  } else {
    return policyRuleSet.sickDaysBeforeUnexcusedAbsence || 0
  }
}

export const getExcusedSickDaysCount = ({
  associate,
  policyRuleSet,
  excusedAbsences: _excusedAbsences,
  fromDate,
}) => {
  fromDate = fromDate || new Date()
  let lastResetDate = new Date(
    new Date().getFullYear(),
    (policyRuleSet?.sickDaysRefreshMonth || 1) - 1,
    policyRuleSet?.sickDaysRefreshDay || 1
  )
  if (dayjs(fromDate).isBefore(dayjs(lastResetDate))) {
    lastResetDate = dayjs(lastResetDate).subtract(1, 'year').toDate()
  }
  const excusedAbsences = (_excusedAbsences || []).filter(
    (absence) =>
      ['sick', 'sickHalfDay'].includes(absence.type) &&
      (dayjs(absence.date).isAfter(lastResetDate) ||
        dayjs(absence.date).isSame(lastResetDate))
  )

  return excusedAbsences.reduce(
    (total, absence) => total + (absence.type === 'sickHalfDay' ? 0.5 : 1),
    0
  )
}
